import React from 'react';
import { graphql } from 'gatsby';

import { Layout, Page as P, Seo } from '@app/components';

import items from '@app/assets/prices/tummy-tuck-surgery-1.json';

const Page: React.FC = () => {
  return (
    <Layout>
      <Seo title="TUMMY TUCK SURGERY PRICES - Clinic SBB International" />
      <P.Head
        title="TUMMY TUCK SURGERY PRICES"
        date="January 22, 2022"
        breadcrumb={[
          { path: '/', name: 'Home' },
          {
            path: '/tummy-tuck-surgery-sterling',
            name: 'TUMMY TUCK SURGERY PRICES',
          },
        ]}
      />

      <div className="py-16">
        <div className="container mx-auto px-4">
          <div className="grid lg:grid-cols-4 gap-6 items-start">
            <div className="col-span-3">
              <h1 className="border-b text-4xl pb-4">TUMMY TUCK SURGERY PRICES</h1>
              <h2 className="mt-16 text-2xl">TUMMY TUCK SURGERY</h2>
              <table className="min-w-full border-collapse border mt-8">
                <thead className="bg-white">
                  <tr>
                    <th className="border py-3 px-6 text-lg font-bold tracking-wider text-left text-gray-900">
                      Operation
                    </th>
                    <th className="border py-3 px-6 text-lg font-bold tracking-wider text-left text-gray-900">
                      Days At Hospital
                    </th>
                    <th className="border py-3 px-6 text-lg font-bold tracking-wider text-left text-gray-900">
                      Price Range
                    </th>
                    <th className="border py-3 px-6 text-lg font-bold tracking-wider text-left text-gray-900">
                      Length of stay
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item) => (
                    <tr
                      className="bg-white border-b odd:bg-white even:bg-gray-100 hover:bg-gray-200"
                      key={item.operation}
                    >
                      <td className="border py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                        {item.operation}
                      </td>
                      <td className="border py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                        {item.daysAtHospital}
                      </td>
                      <td className="border py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                        {item.priceRange}
                      </td>
                      <td className="border py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                        {item.lengthOfStay}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
